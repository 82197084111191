/* eslint-disable camelcase */
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import CandidateDetails from 'src/components/organisms/SelectMatchingCandidates/components/CandidateDetails/CandidateDetails';
import CandidatesList from 'src/components/organisms/SelectMatchingCandidates/components/CandidatesList/CandidatesList';
import { getUUID } from 'src/utils/getUUID';
import styles from './SelectMatchingCandidates.module.scss';

export interface IAIModel {
  id: string;
  type: string;
  version: number;
}

export interface IAICandidate {
  id: string;
  name: string;
  resume: string;
  score: number;
  isShortlisted: boolean;
}

interface ISelectMatchingCandidatesProps {
  jobDescription: string;
  type: string;
}

const SelectMatchingCandidates = ({ jobDescription, type }: ISelectMatchingCandidatesProps) => {
  const [chosenModel, setChosenModel] = useState<IAIModel>();
  const [AIModelsList, setAIModelsList] = useState<IAIModel[]>([]);
  const [searchCandidateName, setSearchCandidateName] = useState<string>('');
  const [candidatesList, setCandidatesList] = useState<IAICandidate[]>([]);
  const [chosenCandidate, setChosenCandidate] = useState<IAICandidate | null>(null);
  const [numberOfCandidates, setNumberOfCandidates] = useState<number>(20);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleShowMoreCandidates = () => {
    setNumberOfCandidates(numberOfCandidates + 20);
  };

  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const getCandidates = (data: any): IAICandidate[] =>
    data
      ? data.map((candidate: IAICandidate) => ({
          ...candidate,
          name: candidate.name
            .toLowerCase()
            .split(' ')
            .map((word) => word.split('-').map(capitalize).join('-'))
            .join(' '),
          score: (candidate.score * 100).toFixed(2),
          isShortlisted: false,
        }))
      : [];

  const fetchCandidates = async (model: IAIModel | undefined) => {
    if (!model) return null;
    let token = sessionStorage.getItem('idToken');
      token = token ? JSON.parse(token) : '';
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/ai/search_candidates`,
      {
        model_type: model.type,
        model_version: model.version,
        job_description: jobDescription.replace(/(\r\n|\n|\r)/gm, ''),
        n_candidates: numberOfCandidates,
      },
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return JSON.parse(response.data);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCandidates(chosenModel).then((data) => {
      setCandidatesList(getCandidates(data));
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfCandidates]);

  useEffect(() => {
    setIsLoading(true);
    setCandidatesList([]);
    if (chosenCandidate) setChosenCandidate(null);

    fetchCandidates(chosenModel).then((data) => {
      setCandidatesList(getCandidates(data));
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenModel, jobDescription]);

  useEffect(() => {
    setPageLoading(true);
    const fetchModels = async () => {
      let token = sessionStorage.getItem('idToken');
      token = token ? JSON.parse(token) : '';
      const response = await fetch(`${process.env.REACT_APP_API_URL}/ai/models`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const models = JSON.parse(data).map((model: IAIModel) => ({ ...model, id: getUUID() }));

      setAIModelsList(models ? models.map((model: IAIModel) => ({ ...model, id: getUUID() })) : []);
      setChosenModel(models[0] || undefined);
      setPageLoading(false);
    };

    fetchModels();
  }, []);

  const debouncedSearch = useCallback(
    (query: string) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(async () => {
        if (query) {
          try {
            let token = sessionStorage.getItem('idToken');
            token = token ? JSON.parse(token) : '';
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/ai/search_candidate_position`,
              {
                bullhorn_id: query,
                model_type: chosenModel?.type,
                model_version: chosenModel?.version,
                job_description: jobDescription,
              },
              {
                headers: {
                  'Content-Type': 'application/json; charset=utf-8',
                  Authorization: `Bearer ${token}`,
                },
              },
            );
            setCandidatesList(response.data);
          } catch (error) {
            console.error(error);
          }
        }
      }, 500); // 500ms opóźnienia
    },
    [chosenModel?.type, chosenModel?.version, jobDescription],
  );

  useEffect(() => {
    debouncedSearch(searchCandidateName);
  }, [searchCandidateName, debouncedSearch]);

  const handleShortlistedChange = (id: string) => {
    setCandidatesList(
      candidatesList.map((candidate) =>
        candidate.id === id ? { ...candidate, isShortlisted: !candidate.isShortlisted } : candidate,
      ),
    );
  };

  if (pageLoading)
    return (
      <div className={styles.selectMatchingCandidatesContainer}>
        <LoadingSpinner />
      </div>
    );

  return (
    <div className={styles.selectMatchingCandidatesContainer}>
      {(!jobDescription || jobDescription === '') && <p>Please provide a job description.</p>}
      {(jobDescription) && !chosenCandidate && (
        <CandidatesList
          candidatesList={candidatesList}
          AIModelsList={AIModelsList}
          setChosenModel={setChosenModel}
          searchCandidateName={searchCandidateName}
          setSearchCandidateName={setSearchCandidateName}
          handleShortlistedChange={handleShortlistedChange}
          setChosenCandidate={setChosenCandidate}
          chosenModel={chosenModel}
          handleShowMoreCandidates={handleShowMoreCandidates}
          isLoading={isLoading}
          jobDescription={jobDescription}
        />
      )}
      {(jobDescription) && chosenCandidate && (
        <CandidateDetails
          handleShortlistedChange={handleShortlistedChange}
          candidatesList={candidatesList}
          candidate={chosenCandidate}
          setChosenCandidate={setChosenCandidate}
          type={type}
        />
      )}
    </div>
  );
};

export default SelectMatchingCandidates;
